import './App.css';
import HomePage from './content/home-page';



function App() {
  return (
    <div className="App">
      <div className='MainContent'>

        
        

      </div>
      
      <div className="HomeZone"><HomePage/></div>
    
    </div>
  );
}

export default App;
